<template>
  <!-- <div class="poster-box" id="posterHtml2">
    <img class="bg-img" :src="poster" alt="" >
    
    <div class="profile">
      <div class="headimgurl">
        <img :src="headimgurl" alt="" >
      </div>
      <div class="nickname">{{nickname}}</div>
    </div>
    
    <div class="qrcode" >
      <vue-qr class="qrcode-img" :text="qrcode_uri" :size="100" :margin="2"></vue-qr>
    </div>
  </div> -->

  <div
    class="poster-box"
    id="posterHtml2"
  >
    <div
      class="img-box"
      id="imgBox"
    >
      <img
        class="bg-img"
        style="max-width:500px"
        :src="poster"
        alt=""
      >

      <div class="profile">
        <div
          class="headimgurl"
          style="width:50px;height:50px"
        >
          <img
            :src="headimgurl"
            alt=""
          >
        </div>
        <div
          class="nickname"
          style="font-size:16px;margin-left:10px"
        >{{ nickname }}
        </div>
      </div>

      <div
        class="qrcode"
        style="width:75px;height:75px"
      >
        <vue-qr
          class="qrcode-img"
          :text="qrcode_uri"
          :size="100"
          :margin="2"
        ></vue-qr>
      </div>
    </div>

    <Loading :loading="loading"></Loading>

  </div>

</template>

<script>
import VueQr from "vue-qr";
import html2canvas from "html2canvas";
import axios from 'axios'

export default {
  components: {
    VueQr
  },
  data () {
    return {
      loading: false,
      refer: "",
      headimgurl: "https://ceshiren.com/uploads/default/original/1X/809c63f904a37bc0c6f029bbaf4903c27f03ea8a.png",
      nickname: "霍格沃兹测试学院",
      poster: "",
      qrcode_uri: "",
      title: '',
      origin_: '',
      project: '',
    }
  },
  created () {
    this.origin_ = location.origin
    console.log(this.$route.query.project, this.$route.query.refer);
  },
  async mounted () {
    await this.getUserInfo()
    setTimeout(this.saveHtml2Image, 1000);
    document.title = this.title
  },
  methods: {
    //获取用户数据信息
    async getUserInfo () {
      //开启loading
      this.loading = true
      //获取页面参数
      // let project = this.$route.query.project
      this.project = this.$route.query.project
      // let refer = this.$route.query.refer
      this.refer = this.$route.query.refer
      // let referrer = this.$route.query.referrer
      // let bannner = this.$route.query.bannner
      // let arr = [{ project:this.project }, { refer:this.refer }, { referrer: }, { bannner }]
      // let params = {}
      // // 将已有参数添加到params对象中
      // arr.forEach(item => {
      //   if (item[Object.keys(item)[0]]) {
      //     params[Object.keys(item)[0]] = item[Object.keys(item)[0]]
      //   }
      // })
      let params = {
        project: this.project,
        refer: this.refer,
      }

      //将发送参数进行加密，加密结果统一通过data字符串发送
      // const res = await axios.post(`${this.origin_}/backend/poster`, {data: this.$resCommon.reqEncrypt(params)})
      const res = await axios.get(`${this.origin_}/backend/poster`, { params })
      // const res = await axios.post(`http://localhost:5000/poster`, {data: this.$resCommon.reqEncrypt(params)})
      // const res = await axios.get('https://dev.poster.ceba.ceshiren.com/backend/dev/poster', { params })
      console.log(res);
      // 返回结果是加密的，需要解密后再读取参数
      // const resData = this.$resCommon.getResData(res);
      const resData = res.data.data;
      this.title = resData.title
      this.poster = resData.poster
      this.qrcode_uri = resData.qrcode_uri
      this.img_exist = resData.img_exist
      if (resData.headimgurl) {
        console.log(123);
        this.headimgurl = resData.headimgurl
      }
      if (resData.nickname) {
        console.log(456);
        this.nickname = resData.nickname
      }
    },

    //将html结构转换为图片
    saveHtml2Image () {

      //转换图片时 将高度归0 避免出现顶部白边
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;

      html2canvas(document.querySelector('#imgBox'), {
        useCORS: true,
        allowTaint: true,
      }).then(async canvas => {
        const img = document.createElement("img");
        let src = canvas.toDataURL("image/jpeg", 0.9)
        img.src = src
        document.body.appendChild(img);
        img.style.cssText =
          "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:9;";
        img.id = "poster-Img-new"
        //将base64符号转义处理 
        // encodeURIComponent(img.src)
        //转换完成后 将base64提交到后台
        console.log({ img: src, refer: this.refer, project: this.project })
        this.loading = false
        if (this.img_exist) {
          return
        }
        axios.post(`${this.origin_}/backend/recv_img`, { img: src, refer: this.refer, project: this.project })
          // axios.post('https://dev.poster.ceba.ceshiren.com/backend/dev/recv_img', { img: src, refer: this.refer })
          .then(res => {
            console.log(res);
          })
      });
    },
  }
}
</script>

<style scoped>
.poster-box {
  /* position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  overflow: hidden;
  overflow-x: hidden;
}

.img-box {
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.bg-img {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.profile {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  /* top: 15px;
  left: 12px; */
  top: 2%;
  left: 5%;
}

.profile .headimgurl {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}

.profile .nickname {
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
}

.profile .headimgurl img {
  width: 100%;
}

.qrcode {
  /* width: 2rem;
  height: 2rem; */
  position: absolute;
  /* right: 1rem;
  bottom: 0.3rem; */
  z-index: 2;
  right: 5%;
  bottom: 2%;
}

.qrcode .qrcode-img {
  width: 100%;
  height: 100%;
}
</style>